/* DataGridCustom.css */

.status-row {
    /* background-color:#F9B572; */
    color: rgb(223, 22, 22);
  }
  .tagged-color-row {
    transition: background-color 0.2s ease;
  }
  
  /* Override MUI's hover effect */
  .tagged-color-row:hover {
    opacity: 1 !important;
  }

  .linked-job { 
    background-color: #f9b572 !important; /* Light red-orange */
    color: rgb(223, 22, 22) !important; /* Darker red text */
}
